<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
	export default {
		metaInfo: {
			meta: [
				{ name: 'robots', content: process.env.NUXT_ENV_NOFOLLOW || 'noindex, nofollow' }
			]
		}
	}
</script>

<style>
html {
	box-sizing: border-box;
}
*,*:before,*:after {
	box-sizing: inherit;
}
@-ms-viewport {
	width: device-width;
}
*:focus {
	outline:none !important
}
* {
	@apply outline-none;
	-webkit-tap-highlight-color: transparent;
}
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
	outline: none;
}
html, body{
	margin: 0;
	/* font-family: 'Montserrat', sans-serif;
	font-weight: normal; */
	/* overflow-x: hidden; */
	font-size: 16px;
	/* letter-spacing: 0.025em; */
	color: #000;
	/* word-spacing: 1px; */
	@apply antialiased subpixel-antialiased;
	box-sizing: border-box;
	/* scroll-behavior: smooth; */
}
#app {
	@apply font-Montserrat;
}
</style>
