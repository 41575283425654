import Vue from 'vue'
import App from './App.vue'
import router from './router'
import OwlCarousel from 'v-owl-carousel'
import VueSmoothScroll from 'vue2-smooth-scroll'
import CollapseTransition from '@ivanv/vue-collapse-transition'
// import VueGtag from "vue-gtag";
import VueGtm from '@gtm-support/vue2-gtm';
import VueMeta from 'vue-meta'
import './assets/css/tailwind.css'

Vue.config.productionTip = false
Vue.component('carousel', OwlCarousel)
Vue.use(VueSmoothScroll, {
	duration: 1000,
	updateHistory: false,
	// easingFunction: t => (--t)*t*t+1,
})
Vue.component('CollapseTransition', CollapseTransition)
// Vue.use(VueGtag, {
//   config: { id: "AW-349698048" }
// });
Vue.use(VueGtm, {
  id: 'GTM-MXP8M6R',
	defer: false,
});
Vue.use(VueMeta, {
	// optional pluginOptions
	refreshOnceOnNavigation: true
})

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
