<template>
	<div id="mainpage" class="flex flex-col min-h-screen home">

		<SiteHeader />

		<!-- <header class="text-white bg-black">
			<div class="container xl:px-20 py-[15px] lg:py-[25px]">
				<div class="flex items-center justify-between">
					<img class="logo" src="@/assets/images/gsc-logo.jpg" alt="gsc-logo">
					<div class="text-center">
						<div class="mb-3 leading-4">Arrange Your Appointment Today</div>
						<div class="mb-2 text-[30px] leading-[30px] font-semibold">
							<a href="tel:+6581132370">8113 2370</a>
						</div>
						<div class="sched d-none d-lg-block d-xl-block leading-[135%]">
							<strong>Mon – Sat:</strong> 9am – 5pm | <strong>Sunday & P.H:</strong> 9:30am – 5pm
						</div>
					</div>
				</div>
			</div>
		</header> -->

		<div class="section banner-section bg-siteOrange pb-[90px]">
			<carousel
				class=""
				:dots="true"
				:nav="false"
				:loop="false"
				:autoplay="true"
				:autoHeight="false"
				:autoplaySpeed="500"
				:autoplayTimeout="2500"
				:items="1"
			>

				<div class="banner container flex py-20 justify-center items-center lg:h-[800px] xl:px-20">
					<div class="text-center text-white">
						<img class="" src="@/assets/images/japan-car-banner-1.png" alt="japan-car-banner-1">
						<div class="main-text mt-8 text-6xl leading-none uppercase font-Optima xs:text-7xl lg:text-[58px]">
							The Japanese Car Specialist Workshop
						</div>
						<div class="sub-text mt-4 text-lg md:text-2xl lg:text-[30px]">
							Specialise in Servicing, Troubleshooting, Diagnosing, and Repair work
						</div>
					</div>
				</div>

				<div class="banner container flex py-20 justify-center items-center lg:h-[800px] xl:px-20">
					<div class="text-center text-white">
						<img class="" src="@/assets/images/japan-car-banner-2.png" alt="japan-car-banner-2">
						<div class="main-text mt-8 text-6xl leading-none uppercase font-Optima xs:text-7xl lg:text-[58px]">
							The Japanese Car Specialist Workshop
						</div>
						<div class="sub-text mt-4 text-lg md:text-2xl lg:text-[30px]">
							Specialise in Servicing, Troubleshooting, Diagnosing, and Repair work
						</div>
					</div>
				</div>
			</carousel>
		</div>
		<div class="bg-black section form-section">
			<div class="container xl:px-20">
				<div class="relative px-4 py-[30px] bg-white top-[-90px] lg:px-[30px]">
					<div class="mb-10 font-Optima text-center text-[30px] md:text-[26px] lg:text-[34px] tracking-[1.5px] leading-[130%]">
						Speak to Our<br><strong>Japanese Car Specialists Today</strong>
					</div>
					<ContactForm />
				</div>
			</div>
		</div>

		<div class="bg-white section expertise-section">
			<div class="container py-[70px] xl:px-20 space-y-10 lg:space-y-15">

				<div class="grid gap-12 lg:grid-cols-2">

					<div class="">
						<img class="border-[14px] border-siteOrange-2" src="@/assets/images/expertise-profile.jpg" alt="expertise-profile">
					</div>

					<div class="">
						<div class="mb-10 font-Optima text-[30px] md:text-[26px] lg:text-[34px] tracking-[1.5px] leading-[130%]">
							Our Expertise<br>Let You Have An Ease Of Mind
						</div>
						<div class="space-y-4 text-lg text-justify">
							<p>GSC Automotive was founded in 1992 by Mr Soh Hock Yong, a humbled technician who have a passion for cars. With over 35 years’ experience in the automotive industry, he has built a strong team of well-knowledge technicians particularly specialising in Japan made cars: Honda, Toyota, Mazda, Lexus, etc.</p>
							<p><strong>In 2008, GSC is proud to be the first private auto workshop to receive the Hybrid Certification. Mr Soh is one of the very few who have specialised training on hybrid car’s servicing and repair held in USA.</strong> Also in 2010, the auto workshop has gained more recognition in the automotive industry and has won many awards such as the 2010 Successful Entrepreneur Award. Besides awards and certifications garner over the years, GSC Automotive review are also positive in the market, widely trusted by many car owners.</p>
							<p>At GSC Auto, we provide full range continent car services, from regular servicing, minor and major repairs, engine overhaul, brake replacement, wheel alignment, air con servicing and repair, and more.</p>
							<br>
							<img class="lg:w-[60%] w-full mt-4"  src="@/assets/images/cert.jpg" alt="">
						</div>
					</div>

				</div>

				<div class="">
					<div class="mb-5 font-Optima text-[30px] md:text-[26px] lg:text-[34px] tracking-[1.5px] leading-[130%]">
						Our commitments
					</div>
					<ul class="pl-10 text-lg list-disc commitments space-y-[10px]">
						<li>Detailed diagnostic check and inspection</li>
						<li>Trained mechanics. Friendly and helpful</li>
						<li>Treat your car like our own</li>
						<li>Fast turnaround time</li>
						<li>Prices are pocket-friendly and transparent</li>
					</ul>
				</div>

				<div class="flex flex-wrap justify-center">
					<img class="w-1/2 h-auto md:w-auto lg:w-1/5" src="@/assets/images/logo-toyota.png" alt="logo-toyota">
					<img class="w-1/2 h-auto md:w-auto lg:w-1/5" src="@/assets/images/logo-honda.png" alt="logo-honda">
					<img class="w-1/2 h-auto md:w-auto lg:w-1/5" src="@/assets/images/logo-mazda.png" alt="logo-mazda">
					<img class="w-1/2 h-auto md:w-auto lg:w-1/5" src="@/assets/images/logo-lexus.png" alt="logo-lexus">
					<img class="w-1/2 h-auto md:w-auto lg:w-1/5" src="@/assets/images/logo-nissan.png" alt="logo-nissan">
					<img class="w-1/2 h-auto md:w-auto lg:w-1/5" src="@/assets/images/logo-mitsubishi.png" alt="logo-mitsubishi">
					<img class="w-1/2 h-auto md:w-auto lg:w-1/5" src="@/assets/images/logo-subaru.png" alt="logo-subaru">
					<img class="w-1/2 h-auto md:w-auto lg:w-1/5" src="@/assets/images/logo-suzuki.png" alt="logo-suzuki">
					<img class="w-1/2 h-auto md:w-auto lg:w-1/5" src="@/assets/images/logo-infiniti.png" alt="logo-infiniti">
					<img class="w-1/2 h-auto md:w-auto lg:w-1/5" src="@/assets/images/logo-isuzu.png" alt="logo-isuzu">
				</div>

			</div>
		</div>

		<div class="section callus-section bg-siteOrange-2">
			<div class="container py-[70px] xl:px-20">
				<div class="flex flex-wrap justify-center p-5 text-xl font-light leading-[1.2] text-center border-[5px] border-siteOrange md:text-3xl gap-y-4">
					<img class="flex-none lg:pr-4" src="@/assets/images/customer-service.png" alt="customer-service">
					<div class="w-full lg:w-auto">
						Call us at <a class="font-medium text-siteOrange" href="tel:+6581132370">8113 2370</a><br>
						to schedule an appointment
					</div>
				</div>
			</div>
		</div>

		<div class="bg-black section comprehensive-section">
			<div class="container py-[70px] xl:px-20 text-white text-center">
				<div class="mb-10 font-Optima text-[30px] md:text-[26px] lg:text-[34px] tracking-[1.5px] leading-[130%]">
					Comprehensive<br>Car Maintenance & Repair
				</div>
				<p class="mb-15">Well equipped with diagnostic tool for all Japanese cars, our mechanic<br>will be able to accurately advise and assist you.</p>
				<div class="grid gap-4 text-xl font-light md:grid-cols-5 font-Optima">

					<div class="">
						<img class="mx-auto" src="@/assets/images/sequence-1.jpg" alt="sequence-1">
						<div class="mt-5">Regular Servicing</div>
					</div>

					<div class="">
						<img class="mx-auto" src="@/assets/images/sequence-2b.jpg" alt="sequence-2">
						<div class="mt-5">Engine Problem</div>
					</div>

					<div class="">
						<img class="mx-auto" src="@/assets/images/sequence-3b.jpg" alt="sequence-3">
						<div class="mt-5">Battery, Alternator, Starter Faulty</div>
					</div>

					<div class="">
						<img class="mx-auto" src="@/assets/images/sequence-4.jpg" alt="sequence-4">
						<div class="mt-5">Transmission Faulty</div>
					</div>

					<a href="https://carairconservicing.com/" target="_blank" class="block">
						<img class="mx-auto" src="@/assets/images/sequence-5.jpg" alt="sequence-5">
						<div class="mt-5">Car Aircon Faulty</div>
					</a>

				</div>
			</div>
		</div>

		<div class="section callus-section bg-siteOrange-2">
			<div class="container py-[70px] xl:px-20">
				<div class="flex flex-wrap justify-center p-5 text-xl font-light leading-[1.2] text-center border-[5px] border-siteOrange md:text-3xl gap-y-4">
					<img class="flex-none lg:pr-4" src="@/assets/images/customer-service.png" alt="customer-service">
					<div class="w-full lg:w-auto">
						Call us at <a class="font-medium text-siteOrange" href="tel:+6581132370">8113 2370</a><br>
						to schedule an appointment
					</div>
				</div>
			</div>
		</div>

		<div class="text-center text-white section trustus-section bg-siteOrange">
			<div class="container pt-[70px] pb-45 xl:px-20">
				<div class="mb-10 font-medium font-Optima text-[30px] md:text-[26px] lg:text-[60px] lg:mb-[30px] tracking-[1.5px] leading-[130%]">
					Trust Us Your Car
				</div>
				<div class="lg:text-[22px]">With our Well-Equipped Workshop & Experience Team of Mechanics Today</div>
			</div>
		</div>

		<div class="bg-black section form-section">
			<div class="container xl:px-20">
				<div class="relative px-4 py-[30px] bg-white top-[-90px] lg:px-[30px]">
					<div class="mb-10 font-Optima text-center text-[30px] md:text-[26px] lg:text-[34px] tracking-[1.5px] leading-[130%]">
						Speak to Our<br><strong>Japanese Car Specialists Today</strong>
					</div>
					<ContactForm />
				</div>
			</div>
		</div>

		<!-- <div class="bg-white section faq-section">
			<div class="container py-[70px] xl:px-20">
				<div class="mb-10 font-Optima text-center text-[30px] md:text-[26px] lg:text-[34px] tracking-[1.5px] leading-[130%]">
					Our Customers’ Experience
				</div>
				<div class="space-y-8">

					<div id="accordion-1" class="accordion-item" ref="1">
						<div class="accordion">
							<div class="panel" :class="{active:isOpen === 1}" @click="(isOpen === 1 ? isOpen = false : isOpen = 1)">
								<div class="bg-white py-4 flex justify-between items-center space-x-[10px] cursor-pointer panel-header border-b-2 border-gray-900 duration-200" :class="{'!border-siteRed':isOpen === 1}">
									<div class="text-xl leading-tight accordion-title">Car vibration while stationary</div>
									<div class="pr-4">
										<div class="h-[17px] flex-none w-[17px] relative duration-200 origin-center transform">
											<img src="@/assets/images/faq-plus.png" class="absolute top-0 left-0 right-0 mx-auto transform rotate-0 down-0" v-show="isOpen != 1">
											<img src="@/assets/images/faq-minus.png" class="absolute top-0 left-0 right-0 mx-auto transform rotate-0 down-0" v-show="isOpen === 1">
										</div>
									</div>
								</div>
								<collapse-transition>
								<div class="pt-10 pb-5 space-y-4 break-words bg-white panel-body accordion-content" v-show="isOpen === 1" :class="{active:isOpen === 1}">
									<p>Calvin Tham, owner of Mercedes E200 have been a regular at our workshop for the last 7 years since his 1st vehicle.</p>
									<div class="space-y-2">
										<p class="font-bold">Experience:</p>
										<p>Calvin experienced car vibration whenever he stops at traffic lights, or when his car is idling. Initially the vibration was bearable, however the vibration became more vigorous over the weeks.</p>
									</div>
									<div class="space-y-2">
										<p class="font-bold">We solved the problem:</p>
										<ol class="pl-6 list-decimal">
											<li>We were certain that the cause of the problem was either the engine mounting, throttle body, or spark plug</li>
											<li>A diagnostic tool was used to confirm that there isn’t any other fault</li>
											<li>Manual vehicle inspection</li>
										</ol>
									</div>
									<p>After using a diagnostic tool to diagnose the vehicle, we confirmed that there wasn't any other fault code. Through our manual inspection, we confirmed that the engine mounting does not have any cracks and was in good condition. However, we found out that the throttle body was dirty. The throttle body controls the amount of air takes in by the engine. Hence when it was dirty, the air in-take was not smooth which caused the car to vibrate when idle.</p>
									<p>We shared the issue with Calvin and explained the situation to him. We then advised him that neither replacement nor repair was required, cleaning of the throttle body would be sufficient to solve the issue. With detail checking and honest advice, Calvin did not have to spend a bomb to replace any parts of his vehicle to rectify his problem.</p>
									<p>If you are facing the same issue, feel free to check with us for consultation.</p>
								</div>
								</collapse-transition>
							</div>
						</div>
					</div>

					<div id="accordion-1" class="accordion-item" ref="2">
						<div class="accordion">
							<div class="panel" :class="{active:isOpen === 2}" @click="(isOpen === 2 ? isOpen = false : isOpen = 2)">
								<div class="bg-white py-4 flex justify-between items-center space-x-[10px] cursor-pointer panel-header border-b-2 border-gray-900 duration-200" :class="{'!border-siteRed':isOpen === 2}">
									<div class="text-xl leading-tight accordion-title">Coolant pressure light kept coming back even though coolant pump was changed</div>
									<div class="pr-4">
										<div class="h-[17px] flex-none w-[17px] relative duration-200 origin-center transform">
											<img src="@/assets/images/faq-plus.png" class="absolute top-0 left-0 right-0 mx-auto transform rotate-0 down-0" v-show="isOpen != 2">
											<img src="@/assets/images/faq-minus.png" class="absolute top-0 left-0 right-0 mx-auto transform rotate-0 down-0" v-show="isOpen === 2">
										</div>
									</div>
								</div>
								<collapse-transition>
								<div class="pt-10 pb-5 space-y-4 break-words bg-white panel-body accordion-content" v-show="isOpen === 2" :class="{active:isOpen === 2}">
									<p>James Poh, owner of Volkswagen GTI have become a regular at our workshop after we promptly assisted him with his Coolant problem 4 years back.</p>
									<div class="space-y-2">
										<p class="font-bold">Experience:</p>
										<p>James noticed that his car was having coolant issue and visited one of his regular workshop to fix. His previous workshop identified that his coolant was leaking and advised him to replace his coolant tank. However, this did not solve the problem. After a month, the problem resurfaced, the previous workshop shared with him that his coolant pump was faulty and would require a replacement to solve the issue. James then chanced upon us.</p>
									</div>
									<div class="space-y-2">
										<p class="font-bold">We solved the problem:</p>
										<ol class="pl-6 list-decimal">
											<li>We used a diagnostic tool to diagnose the problem</li>
											<li>Manual vehicle inspection</li>
										</ol>
									</div>
									<p>Indeed, the coolant pump was faulty. However, that was not the only problem we have detected, the radiator was showing signs of leakage as well. As James’s GTI was already 9 year-old and was on high mileage, it was common that coolant was facing issues due to wear and tear. We advised James that the coolant pump would need to be replaced and also informed him that changing the radiator was not a must since there is only 1 year left on the vehicle. James went ahead with replacing both his coolant pump and radiator, and his problem has not surfaced again. With detailed checking and honest advice, not only we managed to solve James’ problem, we also save him time and trouble.</p>
									<p>Thank you!</p>
								</div>
								</collapse-transition>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div> -->

		<div class="section testimonial-section bg-siteOrange">
			<div class="flex flex-wrap">
				<div class="w-full lg:w-1/2 faq-bg min-h-[700px]"></div>
				<div class="w-full lg:w-1/2">
					<div class="text-white container-half py-[70px] px-4 lg:pl-8 lg:pr-20">
						<div class="mb-10 font-Optima text-center text-[30px] md:text-[26px] lg:text-[34px] tracking-[1.5px] leading-[130%]">
							GSC Automotive Review
						</div>
						
						<carousel
							class=""
							:dots="true"
							:nav="false"
							:loop="false"
							:autoplay="true"
							:autoHeight="false"
							:autoplaySpeed="500"
							:autoplayTimeout="5000"
							:items="1"
						>

							<div class="space-y-4 text-justify leading-[120%]">
								<img class="!w-auto mx-auto" src="@/assets/images/test-01.png" alt="test-01" width="190" height="190">
								<p>am driving a Ford Smax and was having problem with my power steering as it keeps leaking.</p>
								<p>Went around searching for workshops but was given all sorts of theory. And one even recommended keep refilling the powering fluid and even ask me to buy my own spare parts from agent and they will repair it for me plus workmanship. I give a pass.</p>
								<p>I have no choice but to ask google who can help me. And only one result came out GSC Automotive. Call up for appt. and on the very same day the Boss Mr. Soh told me no problem I will get it fix for you. After thoroughly checking the power steering . he told me my power steering rack was damage too.</p>
								<p>I left my car with him as he told me to and he reassure me that he will get it done for me. He would quote the damage $$$ before getting it done.</p>
								<p>All I could say is this workshop solve my car problem.</p>
								<p>Their service is awesome. Ms. Jasmine will call and remind you of your appts</p>
								<p>I would definitely recommend this workshop.</p>
								<p>Thanking Mr Soh and his wonderful staff"</p>
								<p>
									<strong>
									Best Regards<br>
									Michael Kay
									</strong>
								</p>
							</div>

							<div class="space-y-4 text-justify leading-[120%]">
								<img class="!w-auto mx-auto" src="@/assets/images/test-02.png" alt="test-02" width="190" height="190">
								<p>to give full recommendation to others car owner with this auto services given by GSC Auto Services. Their senior staff Mr Soh was very attentive with the problem occurred with our car and the outcome was fantastic and satisfaction.</p>
								<p>They had great packages of car servicing with reasonable prices and the troubleshooting of car problem was excellent I will never failed to come over for servicing here again for years and over.</p>
								<p>I will still support to come over to GSC Auto Services if I have any problem with my car again.</p>
								<p>Thanks to everyone here, the friendly approaching staff they like Mdm Jaslyn and Ms Jasmine, their hospitality very welcoming and thanks Mr Soh for all the good tips given from him about Continental car & Asian car.</p>
								<p>
									<strong>
									Regards,<br>
									Shahrin Bin Kasbol
									</strong>
								</p>
							</div>

							<div class="space-y-4 text-justify leading-[120%]">
								<img class="!w-auto mx-auto" src="@/assets/images/test-03.png" alt="test-03" width="190" height="190">
								<p>I ballu apparo owner of a suzuki swift sjd 279 z come frequently to gsc for servicing and other services. I am very satisfied with their workmanship.,the charges are also reasonable. The, foreman mr.soh is friendly and knowledgeable. The staff are also great. I am glad to recommend their services.</p>
								<p>
									<strong>Ballu apparo</strong>
								</p>
							</div>

							<div class="space-y-4 text-justify leading-[120%]">
								<img class="!w-auto mx-auto" src="@/assets/images/test-04.png" alt="test-04" width="190" height="190">
								<p>I chanced upon GSC during their festive promotions on their servicing packages which were much cheaper . I went online and saw a few of their reviews and it seemed promising.</p>
								<p>I drive a 2006 Toyota altis and I came to GSC to do my servicing and to do a small check on my car. Mr Soh who conducted my check sounded me to suggest renewing my car COE as my car condition was very good. He even suggested which parts need to be replaced due to the current wear and tear if I intended to renew my COE.</p>
								<p>I was very impressed with his honesty and pleased to hear an honest assessment of my car. When I when to make payment to my shock, I found out that Mr Soh was the boss of the company.</p>
								<p>In such times, to see an honest boss who still touches every car that comes into his workshop is truly a gem. I have returned again and again because some things money cant buy. Honest pricing without premium charges.</p>
								<p>Today I am happily driving my “old” car which feels and handles like a new car.</p>
								<p>
									<strong>David Dhushendran</strong>
								</p>
							</div>

							<div class="space-y-4 text-justify leading-[120%]">
								<img class="!w-auto mx-auto" src="@/assets/images/test-05.png" alt="test-05" width="190" height="190">
								<p>I want to take a moment to write a big "Thank-you" for all the great work your company has demonstrated: it's willingness to do things the "right way", and in a manner that keeps the consideration of the customer in mind.</p>
								<p>Specifically, I had some issues with our B170 Merz. We have been going to several workshops before to rectify the problem. Unfortunately, the problem recurred again. Frustrated, I googled to search for an alternative workshop and saw some good reviews about GSC Automotive.</p>
								<p>I was delighted that the owner, Mr Soh, had personally looked into the issues and gave an accurate diagnosis, my car was repaired within 24 hours. Then Mr Soh and team took the EXTRA TIME, with NO EXTRA CHARGE to ensure that my car was in tip top condition before hand over, they were looking into other issues that they had spotted and went beyond their opening hours to rectify them.</p>
								<p>It is a pleasure to go to a workshop when you feel as though the people there are looking out for your vehicle and your best interest. I was treated very professionally and kindly. I was initially concerned about costs and had surveyed around to find that they had an absolutely fair price for the work I needed. I've had no issues so far, and plan to continue going to them for all my car needs.</p>
								<p>Keep up the great work and services, GSC Automotive!</p>
								<p>
									<strong>Badrol</strong>
								</p>
							</div>

							<div class="space-y-4 text-justify leading-[120%]">
								<img class="!w-auto mx-auto" src="@/assets/images/test-06.png" alt="test-06" width="190" height="190">
								<p>Excellent car repair services provided by experienced, highly skillful and friendly mechanics. The repair costs are fairly reasonable. The boss – Mr. Soh is easy going and humble person with a lots of experiences to share with. I have a wonderful morning chats and exchange experiences with him. I would strongly recommended all my friends and relatives to come and try their services!</p>
								<p>A big thumbs up to GSC Auto Services at Northlink Building.</p>
								<p>
									<strong>Chin Jye Fei</strong>
								</p>
							</div>

							<div class="space-y-4 text-justify leading-[120%]">
								<img class="!w-auto mx-auto" src="@/assets/images/test-07.png" alt="test-07" width="190" height="190">
								<p>I chanced upon GSC Auto Services 4 years ago and have since been returning to them each time my car needs their services.</p>
								<p>They are honest, efficient, and reliable. They will troubleshoot and explain clearly to me the problem is and how they are going to rectify it. They always source for quality parts to replace spoilt parts.</p>
								<p>There is no need to look around, wasting time to go round comparing repair prices with other workshops. If quality services is what you are looking for, then this is the place you can trust. They’ll never compromise on their services.</p>
								<p>The staff are committed and they go the extra mile to do unexpected things for me ~ helping me to polish away stain marks on car body, wash my petrol tank, make arrangement to take my car in at odd hours, etc.</p>
								<p>I believe I’ll never be able to find another workshop like them.</p>
								<p>
									<strong>Judy Yap</strong>
								</p>
							</div>

						</carousel>

					</div>
				</div>
			</div>
		</div>

		<div class="section callus-section bg-siteOrange-2">
			<div class="container pt-[70px] pb-[160px] xl:px-20">
				<div class="flex flex-wrap justify-center p-5 text-xl font-light leading-[1.2] text-center border-[5px] border-siteOrange md:text-3xl gap-y-4">
					<img class="flex-none lg:pr-4" src="@/assets/images/customer-service.png" alt="customer-service">
					<div class="w-full lg:w-auto">
						Call us at <a class="font-medium text-siteOrange" href="tel:+6581132370">8113 2370</a><br>
						to schedule an appointment
					</div>
				</div>
			</div>
		</div>

		<div class="bg-black section form-section">
			<div class="container xl:px-20">
				<div class="relative px-4 py-[30px] bg-white top-[-90px] lg:px-[30px]">
					<div class="mb-10 font-Optima text-center text-[30px] md:text-[26px] lg:text-[34px] tracking-[1.5px] leading-[130%]">
						Speak to Our<br><strong>Japanese Car Specialists Today</strong>
					</div>
					<ContactForm />
				</div>
			</div>
		</div>

		<SiteFooter />

	</div>
</template>

<script>
// @ is an alias to /src
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import ContactForm from '@/components/ContactForm.vue'
import IconPhone from '@/components/svg/IconPhone.vue'
import IconFB from '@/components/svg/IconFB.vue'

export default {
	name: 'index',
	components: {
		SiteHeader,
		SiteFooter,
		ContactForm,
		IconPhone,
		IconFB,
	},
	data() {
		return {
			isOpen: 0,
		}
	},
}
</script>

<style>
.banner-section {
	background-image: url('~@/assets/images/banner-bg.png');
	@apply bg-center bg-cover bg-no-repeat;
}

.trustus-section,
.faq-bg {
	background-image: url('~@/assets/images/testimony.jpg');
}
.trustus-section {
	@apply bg-right bg-cover bg-no-repeat;
}
.faq-bg {
	@apply bg-center bg-cover bg-no-repeat;
}

@screen lg {
	.commitments {
		column-count: 2;
	}
}

</style>